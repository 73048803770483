<template>
    <v-card class="mt-2 ml-2 mr-2">
        <FJenisProsedurTable class="pl-2 pr-2" ref="refJenisProsedurTable"/>
    </v-card>
  </template>
  
  <script>
  import FJenisProsedurTable from "@/components/admin_setup/jenis_prosedur/FJenisProsedurTable";
  
  export default {
    name: "JenisProsedur",
    components: {
      FJenisProsedurTable
    },
    data() {
      return {
        tabTitle: 'JenisProsedur'
      }
    },
    computed: {
    },
    methods: {
      tabJenisProsedurClick(){
        this.$refs.refJenisProsedurTable.fetchParent()
      },
  
    },
  
  
  }
  </script>
  
  <style scoped>
  </style>